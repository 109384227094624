<p-table *ngIf="!addChoice" tableStyleClass="djc-table" [loading]="choicesLoading" [value]="choices" selectionMode="single" dataKey="id"
    (onRowSelect)="selectChoice($event)" editMode="row">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 4em"></th>
            <th>{{ 'base.strName' | translate }}</th>
            <th>Tag</th>
            <th style="width: 10em">{{ 'masterdata.hasFields' | translate }}</th>
            <th style="width: 10em">{{ 'masterdata.isField' | translate }}</th>
            <th>{{ 'masterdata.quotationText' | translate }}</th>
            <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-choicesData let-ri="rowIndex">
        <tr [pSelectableRow]="choicesData">
            <td>{{ level }} - {{ ri + 1 }}</td>
            <td>{{ choicesData.name }}</td>
            <td>{{ choicesData.replacesTag }}</td>
            <td><p-checkbox [ngModel]="choicesData.hasFields" disabled="true" [binary]="true"></p-checkbox></td>
            <td><p-checkbox [ngModel]="choicesData.isField" disabled="true" binary="true"></p-checkbox></td>
            <td>{{ choicesData.quotationText }}</td>
            <td *ngIf="_auth.modules['masterdata'].authLevel >= 20">
                <button pButton type="button" icon="fa fa-pencil" class="ui-button-info"
                        style="margin-right: .3em;margin-left: .2em" (click)="editChoice(choicesData)"></button>
                <button pButton type="button" icon="fa fa-times" class="ui-button-danger"
                        style="margin-right: .3em;margin-left: .2em" (click)="deleteChoice(choicesData.id)"></button>
            </td>
        </tr>
        <tr *ngIf="choicesData.id === selectedChoice.id && selectedChoice.id !== '' && selectedChoice.hasFields">
            <td colspan="5"><choices [parentId]="selectedChoice.id" [addChoice]="false" [level]="level + 1" [description]="selectedChoice.quotationText"></choices></td>
        </tr>
    </ng-template>
</p-table>
<p-dialog appendTo="body" [(visible)]="choiceEdit" styleClass="pDialog ui-g-6" [modal]="true" (onHide)="cancelEditChoice()">
    <form [formGroup]="choicesForm" novalidate (ngSubmit)="saveChoice()">
        <div class="ui-g">
            <div class="ui-g-3"><label for="name">{{ 'base.strName' | translate }}</label></div>
            <div class="ui-g-9"><input pInputText type="text" formControlName="name" id="name"></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">{{ 'masterdata.hasFields' | translate }}</div>
            <div class="ui-g-1"><p-checkbox formControlName="hasFields" (onChange)="hasFieldsChange($event)" binary="true"></p-checkbox></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">{{ 'masterdata.isField' | translate }}</div>
            <div class="ui-g-1"><p-checkbox formControlName="isField" (onChange)="isFieldChange($event)" binary="true"></p-checkbox></div>
        </div>
        <div>
            <div *ngIf="_isFieldFieldSet" class="ui-g">
                <div class="ui-g-3"><label for="fieldType">{{ 'masterdata.fieldType' | translate }}</label></div>
                <div class="ui-g-9"><p-dropdown formControlName="fieldType" id="fieldType" [options]="fieldTypes" (onChange)="fieldTypeChange($event)"></p-dropdown></div>
            </div>
            <div *ngIf="_isOptionList" class="ui-g">
                <div class="ui-g-3"><label for="optionList">Optielijst</label></div>
                <div class="ui-g-9"><input pInputText type="text" placeholder="Veldscheidingsteken = ;" formControlName="optionList" id="optionList" /></div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="replacesTag">{{ 'masterdata.replacesTag' | translate }}</label></div>
            <div class="ui-g-9"><p-dropdown formControlName="replacesTag" id="replacesTag" [options]="fieldTags"></p-dropdown></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="quotationText">{{ 'masterdata.quotationText' | translate }}</label></div>
            <div class="ui-g-9">
                <textarea pInputTextarea formControlName="quotationText" id="quotationText" rows="5" cols="120"></textarea>
            </div>
        </div>
    </form>
    <button type="button" pButton icon="fa fa-save" label="{{ 'base.save' | translate}}" (click)="saveChoice()"></button>
</p-dialog>
